@import './Achieved/achievedStyles.scss';

.simulations,
.achieved {

  input,
  select {
    border: none;
    text-align: right;
    color: blue;
    font-size: 16px;
  }
}

h1:hover {
  color: blue;
  cursor: pointer;
}

h3 {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.bordered-table {
  border: 1px solid black;
  border-collapse: collapse;

  th,
  td {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 0.2em 0.5em;
  }

  thead tr {
    border-bottom: 1px solid black;
  }
}